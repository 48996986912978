'use client'

import React, { useEffect, useState } from 'react';

import ModalCart from './ModalCart';
import OrderClosedModal from '../OrderClosedModal';
import { isDeliveryTime } from '@/utils/helpers';

const GlobalCart = () => {

    const [openModalCart, setOpenModalCart] = useState(false);
    const [orderclosed, setOrderClosed] = useState(false);
    const [openOrderClosedModal, setOpenOrderClosedModal] = useState(false);
    const isOpen = isDeliveryTime();


    useEffect(() => {
        const isOpen = isDeliveryTime();
        setOrderClosed(!isOpen);
      }, []);

      const openCartModal = () => {
        if (!isOpen) {
          setOpenOrderClosedModal(true);
        } else {
            console.log(isOpen);
          setOpenModalCart(true);
        }
      };

    useEffect(() => {
        // Function to handle the custom "open-cart" message
        const handleOpenCart = (event: MessageEvent) => {
            if (event.data == 'open-cart') {
                openCartModal(); // Open the cart modal when "open-cart" message is received
            }
        };

        // Add an event listener for messages
        window.addEventListener('message', handleOpenCart);
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleOpenCart);
        };
    }, []);

    return (
        <>
            <ModalCart
                open={openModalCart}
                closeModal={() => setOpenModalCart(false)}
            />
            {orderclosed && (
        <div onClick={openCartModal} className="bg-red-500 p-3 text-center text-sm flex justify-center text-white">
          Dear customer, online order services are unavailable at this time. Our
          services will be active from 8am to 6pm. Thank you!
        </div>
      )}
      <OrderClosedModal
        open={openOrderClosedModal}
        closeModal={() => {
          setOpenOrderClosedModal(false);
        }}
      />
        </>
    );
}


export default GlobalCart;

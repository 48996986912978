import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@/types/Auth.types';

type NewsStates = {

  news: any[];

};

const initialState: NewsStates = {
  news: [],
 
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    addToNews: (state, { payload }: PayloadAction<string[]>) => {
      state.news = payload;
    },
    
  },
});

const { actions, reducer: NewsReducer } = newsSlice;

export const { addToNews } = actions;
export default NewsReducer;

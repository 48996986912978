import { combineReducers } from 'redux';
import AuthReducer from './slices/auth.slice';
import CategoryReducer from './slices/category.slice';
import CartReducer from './slices/cart.slice';
import NewsReducer from './slices/news.slice';
import UserLocationReducer from './slices/userLocation.slice';
import checkoutReducer from './slices/checkout.slice';

const rootReducer = combineReducers({
  auth: AuthReducer,
  category: CategoryReducer,
  cart: CartReducer,
  news: NewsReducer,
  userLocation: UserLocationReducer,
  checkout: checkoutReducer,
});

export default rootReducer;

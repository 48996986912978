import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import AppStorage from '@/utils/helpers';

type CheckoutState = {
  orderType: 'delivery' | 'pickup' | null;
};

const loadCartFromAppStorage: () => CheckoutState['orderType'] = () => {
  if (typeof window !== 'undefined') {
    const orderType = AppStorage.get('orderType');
    return orderType;
  }
};

const initialState: CheckoutState = {
  orderType: loadCartFromAppStorage(),
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setOrderType: (
      state,
      { payload }: PayloadAction<CheckoutState['orderType']>
    ) => {
      state.orderType = payload;
      saveOrderType(payload);
    },
  },
});

const saveOrderType = (orderType: CheckoutState['orderType']) => {
  AppStorage.save('orderType', orderType);
};

const { actions, reducer: checkoutReducer } = checkoutSlice;

export const { setOrderType } = actions;
export default checkoutReducer;
